import { useContext, useState } from 'react'
import { AuthContext } from '../../contexts/AuthContext'
import { Flex } from '@radix-ui/themes'

import * as Form from '@radix-ui/react-form'
import rurauth from '../../assets/images/rurauth.png'
import Notification from '../../components/notification'
import Loading from '../../components/loading'

import './styles.css'

type NotificationProps = {
  title: string
  description: string
}

export default function Login() {
  const { signUp } = useContext(AuthContext)

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [phone, setPhone] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')

  const [waitingRequest, setWaitingRequest] = useState(false)

  const [notificationProps, setNotificationProps] = useState<NotificationProps>(
    { title: '', description: '' }
  )
  const [notificationOpen, setNotificationOpen] = useState<boolean>(false)

  function handleSubmit(e: any) {
    e.preventDefault()
    setWaitingRequest(true)
    signup()
  }

  async function signup() {
    const { error, status } = await signUp({
      name,
      email,
      password,
      phone,
      city,
      state,
    }).finally(() => setWaitingRequest(false))

    if (status === 201) {
      // user created
      window.location.href = '/account-created'
      return
    }

    setNotificationProps({
      title: 'Erro!',
      description: error || 'Oops, falha na requisição.',
    })
    setNotificationOpen(true)
  }

  return (
    <div className='signup'>
      <div className='form__box'>
        <img src={rurauth} alt='ruralhub' className='logo__app' />

        <div className='form__box__title'>
          <h2>cadastro</h2>

          <hr />
        </div>

        <Form.Root className='form__root__signup'>
          {/** name */}
          <Form.Field className='form__field' name='name'>
            <div
              style={{
                display: 'flex',
                alignItems: 'baseline',
                justifyContent: 'space-between',
              }}
            >
              <Form.Label className='form__label'>nome</Form.Label>
              <Form.Message className='form__message' match='valueMissing'>
                informe o seu nome
              </Form.Message>
            </div>
            <Form.Control asChild>
              <input
                className='input__login'
                type='text'
                required
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Control>
          </Form.Field>

          {/** e-mail */}
          <Form.Field className='form__field' name='email'>
            <div
              style={{
                display: 'flex',
                alignItems: 'baseline',
                justifyContent: 'space-between',
              }}
            >
              <Form.Label className='form__label'>email</Form.Label>
              <Form.Message className='form__message' match='valueMissing'>
                informe o seu e-mail
              </Form.Message>
              <Form.Message className='form__message' match='typeMismatch'>
                informe um e-mail válido
              </Form.Message>
            </div>
            <Form.Control asChild>
              <input
                className='input__login'
                type='email'
                required
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Control>
          </Form.Field>

          {/** password */}
          <Form.Field className='form__field' name='password'>
            <div
              style={{
                display: 'flex',
                alignItems: 'baseline',
                justifyContent: 'space-between',
              }}
            >
              <Form.Label className='form__label'>senha</Form.Label>
              <Form.Message className='form__message' match='valueMissing'>
                informe a sua senha
              </Form.Message>
            </div>
            <Form.Control asChild>
              <input
                className='input__login'
                type='password'
                required
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Control>
          </Form.Field>

          {/** confirmPassword */}
          <Form.Field className='form__field' name='confirmPassword'>
            <div
              style={{
                display: 'flex',
                alignItems: 'baseline',
                justifyContent: 'space-between',
              }}
            >
              <Form.Label className='form__label'>confirmar senha</Form.Label>
              {confirmPassword && confirmPassword !== password ? (
                <Form.Message className='form__message'>
                  senhas diferentes
                </Form.Message>
              ) : null}
            </div>
            <Form.Control asChild>
              <input
                className='input__login'
                type='password'
                required
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </Form.Control>
          </Form.Field>

          {/** phone */}
          <Form.Field className='form__field' name='phone'>
            <div
              style={{
                display: 'flex',
                alignItems: 'baseline',
                justifyContent: 'space-between',
              }}
            >
              <Form.Label className='form__label'>telefone</Form.Label>
              <Form.Message className='form__message' match='typeMismatch'>
                informe um número válido
              </Form.Message>
            </div>
            <Form.Control asChild>
              <input
                className='input__login'
                type='tel'
                required
                onChange={(e) => setPhone(e.target.value)}
              />
            </Form.Control>
          </Form.Field>

          {/** city and state */}
          <Flex justify='between' style={{ gap: 10 }}>
            <Form.Field className='form__field' name='city'>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'baseline',
                  justifyContent: 'space-between',
                }}
              >
                <Form.Label className='form__label'>cidade</Form.Label>
                <Form.Message className='form__message' match='valueMissing'>
                  informe uma cidade
                </Form.Message>
              </div>
              <Form.Control asChild>
                <input
                  className='input__login'
                  type='text'
                  required
                  style={{ width: 200 }}
                  onChange={(e) => setCity(e.target.value)}
                />
              </Form.Control>
            </Form.Field>

            <Form.Field className='form__field' name='state'>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'baseline',
                  justifyContent: 'space-between',
                }}
              >
                <Form.Label className='form__label'>UF</Form.Label>
                <Form.Message className='form__message' match='valueMissing'>
                  *
                </Form.Message>
              </div>
              <Form.Control asChild>
                <input
                  className='input__login'
                  type='text'
                  required
                  style={{ width: 90 }}
                  onChange={(e) => setState(e.target.value)}
                />
              </Form.Control>
            </Form.Field>
          </Flex>
        </Form.Root>

        <Flex
          justify='between'
          style={{ padding: '8px 0', columnGap: 8, width: '100%' }}
        >
          <button
            onClick={() => (window.location.href = '/')}
            className='button__signup violet'
          >
            fazer login
          </button>

          <button
            disabled={!!(!confirmPassword || confirmPassword !== password)}
            type='button'
            className={`button__signup green ${
              confirmPassword && confirmPassword === password
                ? 'enabled'
                : 'disabled'
            }`}
            onClick={(e) => handleSubmit(e)}
          >
            {waitingRequest ? <Loading size='small' /> : 'cadastrar'}
          </button>
        </Flex>

        <Notification
          state={notificationOpen}
          description={notificationProps.description}
          title={notificationProps.title}
          handleState={setNotificationOpen}
        />
      </div>
    </div>
  )
}
