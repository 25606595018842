import * as Dialog from '@radix-ui/react-dialog'
import * as NavigationMenu from '@radix-ui/react-navigation-menu'
import { ExternalLinkIcon, LayersIcon } from '@radix-ui/react-icons'
import { useContext, useState } from 'react'
import { AuthContext } from '../../../contexts/AuthContext'
import ChangePassword from '../change-password'
import { api } from '../../../services/api'
import './styles.css'

export default function Profile() {
  const { setSelectedCompany } = useContext(AuthContext)

  const [waitingChangePasswordRequest, setWaitingChangePasswordRequest] =
    useState(false)

  async function changePassword(password: string) {
    setWaitingChangePasswordRequest(true)
    try {
      const tag = sessionStorage.getItem('@RURAUTH:tag')
      await api.put('/usuarios/recuperar-senha', {
        senha: password,
        tag,
      })
      setWaitingChangePasswordRequest(false)
    } catch (err) {
      setWaitingChangePasswordRequest(false)
    }
  }

  return (
    <Dialog.Root>
      <Dialog.Trigger asChild>
        <NavigationMenu.Item>
          <NavigationMenu.Link className='navigation__menu__link'>
            Perfil
          </NavigationMenu.Link>
        </NavigationMenu.Item>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className='dialog__overlay' />
        <Dialog.Content className='dialog__content'>
          <Dialog.Title className='dialog__title'>Perfil</Dialog.Title>
          <Dialog.Description className='dialog__description'>
            Gerencie seus dados de acesso e suas informações.
          </Dialog.Description>

          <div className='dialog__options'>
            <ChangePassword
              callback={changePassword}
              waitingRequest={waitingChangePasswordRequest}
            />

            <div
              className='dialog__option'
              onClick={() => setSelectedCompany(undefined)}
            >
              <Dialog.Description className='option__description'>
                Empresas
              </Dialog.Description>

              <LayersIcon />
            </div>
          </div>

          <div className='help'>
            <a href=''>Preciso de ajuda</a>

            <ExternalLinkIcon />
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
