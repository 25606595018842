import * as Dialog from '@radix-ui/react-dialog'
import { Cross2Icon, PlusIcon } from '@radix-ui/react-icons'
import { Button } from '@radix-ui/themes'
import { useState } from 'react'
import './styles.css'

type CreateModuleProps = {
  callback: (description: string) => void
}

export default function CreateModule({ callback }: CreateModuleProps) {
  const [description, setDescription] = useState('')

  function close() {
    setDescription('')
    callback(description)
  }

  return (
    <Dialog.Root>
      <Dialog.Trigger asChild>
        <Button className="add__button">
          <PlusIcon width="22" height="22" color="white" />
        </Button>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="dialog__overlay" />
        <Dialog.Content className="dialog__content">
          <Dialog.Title className="dialog__title">Cadastrar módulo</Dialog.Title>
          <fieldset className="fieldset" style={{ paddingTop: 20 }}>
            <label className="label" htmlFor="description">
              Descrição
            </label>
            <input
              className="input"
              id="description"
              type="description"
              onChange={(e) => setDescription(e.target.value)}
            />
          </fieldset>
          <div style={{ display: 'flex', marginTop: 25, justifyContent: 'flex-end' }}>
            <Dialog.Close asChild disabled={!(description.length > 3)}>
              <button className={`button ${description.length > 3 ? 'enable' : 'disable'}`} onClick={() => close()}>
                Cadastrar
              </button>
            </Dialog.Close>
          </div>
          <Dialog.Close asChild>
            <button className="icon__button" aria-label="Close">
              <Cross2Icon />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
