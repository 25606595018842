import { BrowserRouter, Route, Routes } from 'react-router-dom'
import AcceptInvite from './pages/accept-invite'
import Companies from './pages/companies'
import CompanyModules from './pages/company-modules'
import CompanyRelations from './pages/company-relations'
import Login from './pages/login'
import Modules from './pages/modules'
import ModulesRelations from './pages/modules-relations'
import Signup from './pages/signup'
import Users from './pages/users'
import AccountCreated from './pages/account-created'
import ConfirmAccount from './pages/confirm-account'
import RecoverPassword from './pages/recover-password'
import EmailSent from './pages/email-sent'
import CompaniesAdmins from './pages/companies-admins'

export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Login />} />
        <Route path='/signup' element={<Signup />} />
        <Route path='/relations/companies' element={<CompanyRelations />} />
        <Route path='/relations/modules' element={<ModulesRelations />} />
        <Route
          path='/relations/modules/companies'
          element={<CompanyModules />}
        />
        <Route path='/companies' element={<Companies />} />
        <Route path='/companies/admins' element={<CompaniesAdmins />} />
        <Route path='/modules' element={<Modules />} />
        <Route path='/invite' element={<AcceptInvite />} />
        <Route path='/account-created' element={<AccountCreated />} />
        <Route path='/email-sent' element={<EmailSent />} />
        <Route path='/confirm-account' element={<ConfirmAccount />} />
        <Route path='/recover-password' element={<RecoverPassword />} />
        <Route path='/users' element={<Users />} />
      </Routes>
    </BrowserRouter>
  )
}
