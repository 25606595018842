import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  Cross2Icon,
  PlusIcon,
} from '@radix-ui/react-icons'
import { Button } from '@radix-ui/themes'
import { useEffect, useState } from 'react'
import { Module } from '../../../interfaces/module'
import { api } from '../../../services/api'
import { Company } from '../../../pages/companies'

import Loading from '../../loading'
import * as Select from '@radix-ui/react-select'
import * as Dialog from '@radix-ui/react-dialog'

import './styles.css'
import { User } from '../../../pages/users'

type CreateCompanyAdminRelationProps = {
  callback: (moduleId: number) => void
  company?: Company
}

export default function CreateCompanyAdminRelation({
  callback,
  company,
}: CreateCompanyAdminRelationProps) {
  const [selectedModuleId, setSelectedModuleId] = useState<number | undefined>()

  const [users, setUsers] = useState<User[]>([])
  const [loadingUsers, setLoadingUsers] = useState(false)

  useEffect(() => {
    const storedModules = sessionStorage.getItem('@RURAUTH:users')
    if (!storedModules) {
      listUsers()
    } else {
      setUsers(JSON.parse(storedModules))
    }
  }, [])

  function close() {
    setSelectedModuleId(undefined)
    if (selectedModuleId) {
      callback(selectedModuleId)
    }
  }

  async function listUsers() {
    setLoadingUsers(true)

    const { data } = await api.get('/usuarios')
    const users = data.usuarios.map((user: User) => ({
      id: user.id,
      email: user.email,
    }))

    sessionStorage.setItem('@RURAUTH:users', JSON.stringify(users))

    setUsers(users)
    setLoadingUsers(false)
  }

  return (
    <Dialog.Root>
      <Dialog.Trigger asChild>
        <Button className='add__button'>
          <PlusIcon width='22' height='22' color='white' />
        </Button>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className='dialog__overlay' />
        <Dialog.Content className='dialog__content'>
          <Dialog.Title className='dialog__title'>
            Cadastrar administrador da empresa
          </Dialog.Title>

          <div
            style={{
              display: 'flex',
              marginTop: 25,
              justifyContent: 'space-between',
            }}
          >
            {loadingUsers ? (
              <Loading size='small' />
            ) : (
              <Select.Root
                onValueChange={(e) => setSelectedModuleId(Number(e))}
              >
                <Select.Trigger className='select__trigger' aria-label='modulo'>
                  <Select.Value placeholder='Selecione um usuário...' />
                  <Select.Icon className='select__icon'>
                    <ChevronDownIcon />
                  </Select.Icon>
                </Select.Trigger>
                <Select.Portal>
                  <Select.Content className='select__content'>
                    <Select.ScrollUpButton className='select__scroll__button'>
                      <ChevronUpIcon />
                    </Select.ScrollUpButton>
                    <Select.Viewport className='select__viewport'>
                      <Select.Group>
                        <Select.Label className='select__label'>
                          Usuários
                        </Select.Label>
                        {users.map((user) => (
                          <Select.Item
                            className='select__item'
                            value={String(user.id)}
                          >
                            <Select.ItemText>{user.email}</Select.ItemText>
                            <Select.ItemIndicator className='select__item__indicator'>
                              <CheckIcon />
                            </Select.ItemIndicator>
                          </Select.Item>
                        ))}
                      </Select.Group>
                    </Select.Viewport>
                    <Select.ScrollDownButton className='select__scroll__button'>
                      <ChevronDownIcon />
                    </Select.ScrollDownButton>
                  </Select.Content>
                </Select.Portal>
              </Select.Root>
            )}

            <Dialog.Close asChild disabled={!selectedModuleId}>
              <button
                className={`button ${selectedModuleId ? 'enable' : 'disable'}`}
                onClick={() => close()}
              >
                Vincular
              </button>
            </Dialog.Close>
          </div>
          <Dialog.Close asChild>
            <button className='icon__button' aria-label='Close'>
              <Cross2Icon />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
