import { variables } from '../variables'

import axios from 'axios'

const token = sessionStorage.getItem('@RURAUTH:token')

export const api = axios.create({
  baseURL: variables.API_URL,
})

api.defaults.headers.common['api_key'] = 'cb1d8d1d-6059-4a26-8b14-2e3506d1c2c6'

api.interceptors.response.use((response) => {
  if (response.status === 401) {
    sessionStorage.removeItem('@RURAUTH:token')

    window.location.href = '/'
  }

  return response
})

if (token) {
  api.defaults.headers.common['Authorization'] = `Bearer ${token}`
  api.defaults.headers.post['Content-Type'] = 'application/json'
}
