import { useContext, useState } from 'react'
import { Flex } from '@radix-ui/themes'
import { AuthContext } from '../../contexts/AuthContext'

import * as Form from '@radix-ui/react-form'
import Loading from '../../components/loading'
import Notification from '../../components/notification'
import rurauth from '../../assets/images/rurauth.png'

import './styles.css'
import { AxiosError } from 'axios'
import { api } from '../../services/api'

type NotificationProps = {
  title: string
  description: string
}

export default function RecoverPassword() {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const userTag = urlParams.get('t')

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const [waitingRequest, setWaitingRequest] = useState(false)

  const [notificationProps, setNotificationProps] = useState<NotificationProps>(
    { title: '', description: '' }
  )
  const [notificationOpen, setNotificationOpen] = useState<boolean>(false)

  function handleSubmit(e: any) {
    e.preventDefault()
    setWaitingRequest(true)
    userTag ? changePassword() : sendEmail()
  }

  async function changePassword() {
    try {
      await api.put(`/usuarios/recuperar-senha`, {
        senha: password,
        tag: userTag,
      })
      setTimeout(() => {
        setWaitingRequest(false)
        setNotificationProps({
          title: 'Sucesso!',
          description: 'Senha alterada, iremos lhe redirecionar.',
        })
        setNotificationOpen(true)
        setTimeout(() => {
          window.location.href = '/'
        }, 3000)
      }, 1000)
    } catch (err) {
      setNotificationProps({
        title: 'Erro!',
        description:
          err instanceof AxiosError && err.response
            ? err.response.data.error
            : 'Oops, falha na requisição.',
      })
      setNotificationOpen(true)
    }
  }

  async function sendEmail() {
    try {
      await api.post('/usuarios/email/recuperar-senha', { email })

      setWaitingRequest(false)

      window.location.href = '/email-sent'
    } catch (err) {
      setNotificationProps({
        title: 'Erro!',
        description:
          err instanceof AxiosError && err.response
            ? err.response.data.error
            : 'Oops, falha na requisição.',
      })
      setNotificationOpen(true)
    }
  }

  return (
    <div className='recover__password'>
      <div className='form__box__recover__password'>
        <img src={rurauth} alt='ruralhub' className='logo__app' />

        <div className='form__box__recover__password__title'>
          <h2>recuperar</h2>

          <hr />
        </div>

        {userTag ? (
          <Form.Root
            className='form__root__recover__password'
            onSubmit={(e) => handleSubmit(e)}
          >
            {/** password */}
            <Form.Field className='form__field' name='password'>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'baseline',
                  justifyContent: 'space-between',
                }}
              >
                <Form.Label className='form__label'>senha</Form.Label>
                <Form.Message className='form__message' match='valueMissing'>
                  informe a sua senha
                </Form.Message>
              </div>
              <Form.Control asChild>
                <input
                  className='input__login'
                  type='password'
                  required
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Control>
            </Form.Field>

            {/** confirmPassword */}
            <Form.Field className='form__field' name='confirmPassword'>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'baseline',
                  justifyContent: 'space-between',
                }}
              >
                <Form.Label className='form__label'>confirmar senha</Form.Label>
                {confirmPassword && confirmPassword !== password ? (
                  <Form.Message className='form__message'>
                    senhas diferentes
                  </Form.Message>
                ) : null}
              </div>
              <Form.Control asChild>
                <input
                  className='input__login'
                  type='password'
                  required
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </Form.Control>
            </Form.Field>

            <Flex
              justify='between'
              style={{ padding: '8px 0', columnGap: 8 }}
              className='buttons'
            >
              <button
                onClick={() => (window.location.href = '/')}
                type='button'
                className='button__recover violet'
              >
                fazer login
              </button>

              <Form.Submit asChild>
                <button
                  disabled={
                    !!(
                      userTag &&
                      (!confirmPassword || confirmPassword !== password)
                    )
                  }
                  className={`button__recover green ${
                    userTag && confirmPassword && confirmPassword === password
                      ? 'enabled'
                      : 'disabled'
                  }`}
                >
                  {waitingRequest ? <Loading size='small' /> : 'recuperar'}
                </button>
              </Form.Submit>
            </Flex>
          </Form.Root>
        ) : (
          <Form.Root
            className='form__root__recover__password'
            onSubmit={(e) => handleSubmit(e)}
          >
            <Form.Field className='form__field' name='email'>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'baseline',
                  justifyContent: 'space-between',
                }}
              >
                <Form.Label className='form__label'>email</Form.Label>
                <Form.Message className='form__message' match='valueMissing'>
                  informe o seu e-mail
                </Form.Message>
                <Form.Message className='form__message' match='typeMismatch'>
                  informe um e-mail válido
                </Form.Message>
              </div>
              <Form.Control asChild>
                <input
                  className='input__login'
                  type='email'
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </Form.Control>
            </Form.Field>

            <Flex
              justify='between'
              style={{ padding: '8px 0', columnGap: 8 }}
              className='buttons'
            >
              <button
                onClick={() => (window.location.href = '/')}
                type='button'
                className='button__recover violet'
              >
                fazer login
              </button>

              <Form.Submit asChild>
                <button className='button__recover green'>
                  {waitingRequest ? <Loading size='small' /> : 'recuperar'}
                </button>
              </Form.Submit>
            </Flex>
          </Form.Root>
        )}

        <Notification
          state={notificationOpen}
          description={notificationProps.description}
          title={notificationProps.title}
          handleState={setNotificationOpen}
        />
      </div>
    </div>
  )
}
