import { useEffect, useState } from 'react'
import { Pagination } from '@mui/material'
import { Heading, Text } from '@radix-ui/themes'

import Navbar from '../../components/navbar'
import Notification from '../../components/notification'
import ChangeStatusConfirmation from '../../components/modals/change-status-confirmation'

import './styles.css'
import { api } from '../../services/api'
import * as Select from '@radix-ui/react-select'
import { AxiosError } from 'axios'
import { Button } from '@radix-ui/themes'
import CompanyAdmins from '../../components/modals/company-admins'
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  MagnifyingGlassIcon,
} from '@radix-ui/react-icons'
import CreateCompanyAdminRelation from '../../components/modals/create-company-admin-relation'
import { User } from '../users'
import DeleteConfirmation from '../../components/modals/delete-confirmation'
import Loading from '../../components/loading'

export type Company = {
  id: number
  cnpj: string
  razao_social: string
  status: number
}

type NotificationProps = {
  title: string
  description: string
}

export default function CompaniesAdmins() {
  const COMPONENTS_PER_PAGE = 10

  const [companies, setCompanies] = useState<Company[]>([])
  const [companyAdmins, setCompanyAdmins] = useState<User[]>([])

  const [selectedCompanyId, setSelectedCompanyId] = useState<number | null>(
    null
  )

  const [notificationProps, setNotificationProps] = useState<NotificationProps>(
    { title: '', description: '' }
  )
  const [notificationOpen, setNotificationOpen] = useState<boolean>(false)
  const [waitingRequest, setWaitingRequest] = useState(false)

  useEffect(() => {
    listCompanies()
  }, [])

  const [pages, setPages] = useState<number>(0)
  const [pageData, setPageData] = useState<any[]>([
    companies.slice(0, COMPONENTS_PER_PAGE),
  ])

  function handlePaginationChange(event: any, value: number) {
    const startIndex = value * COMPONENTS_PER_PAGE - COMPONENTS_PER_PAGE
    const lastIndex = startIndex + COMPONENTS_PER_PAGE

    if (companies?.length) {
      setPageData(companies.slice(startIndex, lastIndex))
    }
  }

  async function listCompanies() {
    try {
      const { data } = await api.get('/empresas')
      const { empresas } = data

      setCompanies(empresas)
    } catch (err) {
      setNotificationProps({
        title: 'Erro!',
        description:
          err instanceof AxiosError && err.response
            ? err.response.data.error
            : `Falha ao listar as empresas.`,
      })
      setNotificationOpen(true)
    }
  }

  async function listCompanyAdmins() {
    setWaitingRequest(true)

    try {
      const { data } = await api.get(`/empresas/${selectedCompanyId}/admins`)
      const { usuarios } = data

      setCompanyAdmins(usuarios)

      const dataSplited = String(
        usuarios && usuarios.length / COMPONENTS_PER_PAGE
      ).split('.')

      if (dataSplited[1]) {
        setPages(Number(dataSplited[0]) + 1)
      } else {
        setPages(Number(dataSplited[0]))
      }

      setPageData(usuarios.slice(0, COMPONENTS_PER_PAGE))
      setWaitingRequest(false)
    } catch (err) {
      setWaitingRequest(false)
      setNotificationProps({
        title: 'Erro!',
        description:
          err instanceof AxiosError && err.response
            ? err.response.data.error
            : `Falha ao listar os administradores da empresa.`,
      })
      setNotificationOpen(true)
    }
  }

  async function bind(userId: number) {
    try {
      await api.post('/empresas/usuarios', {
        id_empresa: selectedCompanyId,
        id_usuario: userId,
        tipo: 'admin',
      })
      listCompanyAdmins()
    } catch (err) {
      setNotificationOpen(true)
      setNotificationProps({
        title: 'Erro!',
        description:
          err instanceof AxiosError && err.response
            ? err.response.data.error
            : `Oops, houve um erro ao realizar o vínculo.`,
      })
    }
  }

  async function remove(relationId: number) {
    try {
      await api.delete(`/empresas/usuarios/${relationId}`)
      listCompanyAdmins()
    } catch (err) {
      setNotificationOpen(true)
      setNotificationProps({
        title: 'Erro!',
        description:
          err instanceof AxiosError && err.response
            ? err.response.data.error
            : `Oops, houve um erro ao remover o vínculo.`,
      })
    }
  }

  return (
    <div className='main'>
      <Navbar />

      <div className='container company__admins'>
        <div className='header'>
          <Heading className='title'>Administradores de Empresas</Heading>
          <Text>
            Gerencie quais são os administradores das empresas cadastradas.
          </Text>
        </div>

        <div className='table__header'>
          <div className='filter__row'>
            <Select.Root onValueChange={(e) => setSelectedCompanyId(Number(e))}>
              <Select.Trigger className='select__trigger' aria-label='empresa'>
                <Select.Value placeholder='Selecione uma empresa...' />
                <Select.Icon className='select__icon'>
                  <ChevronDownIcon />
                </Select.Icon>
              </Select.Trigger>
              <Select.Portal>
                <Select.Content className='select__content'>
                  <Select.ScrollUpButton className='select__scroll__button'>
                    <ChevronUpIcon />
                  </Select.ScrollUpButton>
                  <Select.Viewport className='select__viewport'>
                    <Select.Group>
                      <Select.Label className='select__label'>
                        Empresas
                      </Select.Label>
                      {companies.map((company) => (
                        <Select.Item
                          className='select__item'
                          value={String(company.id)}
                        >
                          <Select.ItemText>
                            {company.razao_social}
                          </Select.ItemText>
                          <Select.ItemIndicator className='select__item__indicator'>
                            <CheckIcon />
                          </Select.ItemIndicator>
                        </Select.Item>
                      ))}
                    </Select.Group>
                  </Select.Viewport>
                  <Select.ScrollDownButton className='select__scroll__button'>
                    <ChevronDownIcon />
                  </Select.ScrollDownButton>
                </Select.Content>
              </Select.Portal>
            </Select.Root>

            <Button
              className='filter__button'
              onClick={() => listCompanyAdmins()}
            >
              <MagnifyingGlassIcon width='22' height='22' color='white' />
            </Button>
          </div>

          <CreateCompanyAdminRelation
            callback={bind}
            company={companies.find((c) => c.id === selectedCompanyId)}
          />
        </div>

        <div>
          <table>
            {waitingRequest ? (
              <div className='loading__box'>
                <Loading size='medium' />
              </div>
            ) : companyAdmins.length ? (
              <>
                {' '}
                <thead>
                  <tr>
                    <th align='left'>Nome</th>
                    <th align='left'>E-mail</th>
                    <th align='right'>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {pageData.map((relation) => (
                    <tr className='table__row'>
                      <td>{relation.usuarios?.nome}</td>
                      <td>{relation.usuarios?.email}</td>
                      <td align='right'>
                        <DeleteConfirmation
                          id={relation.id}
                          callback={remove}
                          customMessage='Esse usuário deixará de ser administrador e perderá acesso a plataforma do rurauth.'
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </>
            ) : (
              <div className='no__data__found'>
                <span>não há dados, que tal selecionar outra empresa?</span>
              </div>
            )}
          </table>

          <div className='table__footer'>
            {companies.length > 5 ? (
              <Pagination
                count={pages}
                color='primary'
                variant='outlined'
                shape='rounded'
                onChange={handlePaginationChange}
              />
            ) : null}
          </div>

          <Notification
            state={notificationOpen}
            description={notificationProps.description}
            title={notificationProps.title}
            handleState={setNotificationOpen}
          />
        </div>
      </div>
    </div>
  )
}
