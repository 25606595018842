import { useState, useEffect, useContext } from 'react'
import { Cross2Icon } from '@radix-ui/react-icons'
import { AuthContext, UserCompanyAdmin } from '../../../contexts/AuthContext'

import * as RadioGroup from '@radix-ui/react-radio-group'
import * as Dialog from '@radix-ui/react-dialog'

import './styles.css'

export default function CompanyManagementSelection() {
  const userRole = sessionStorage.getItem('@RURAUTH:role')

  const { selectedCompany, setSelectedCompany } = useContext(AuthContext)
  const [checkboxSelectedCompany, setCheckboxSelectedCompany] = useState<
    UserCompanyAdmin | undefined
  >(undefined)

  const companiesManager: UserCompanyAdmin[] = JSON.parse(
    sessionStorage.getItem('@RURAUTH:companiesManager') || '[]'
  )

  useEffect(() => {
    const companyAlreadySelected = sessionStorage.getItem(
      '@RURAUTH:selectedCompany'
    )
    if (companyAlreadySelected) {
      const parsed = JSON.parse(companyAlreadySelected)
      const company = companiesManager.find((c) => c.id === parsed.id)
      setSelectedCompany(company)
    }
  }, [])

  function close() {
    sessionStorage.setItem(
      '@RURAUTH:selectedCompany',
      JSON.stringify(checkboxSelectedCompany)
    )
    setSelectedCompany(checkboxSelectedCompany)
  }

  return (
    <Dialog.Root open={!selectedCompany && userRole !== 'admin'}>
      <Dialog.Portal>
        <Dialog.Overlay className='dialog__overlay' />
        <Dialog.Content className='dialog__content__company__management'>
          <Dialog.Title className='dialog__title'>
            Gerenciar empresa
          </Dialog.Title>
          <Dialog.Description className='dialog__description'>
            Selecione a empresa que deseja gerenciar, você pode alterar
            posteriormente.
          </Dialog.Description>

          <RadioGroup.Root
            onValueChange={(e) =>
              setCheckboxSelectedCompany(
                companiesManager.find((c) => c.id === Number(e))
              )
            }
            className='radio__group__root'
            aria-label='company selection'
          >
            {companiesManager.map((company) => (
              <div className='radio__group__option'>
                <RadioGroup.Item
                  className='radio__group__item'
                  value={`${company.id}`}
                  id={`${company.id}`}
                >
                  <RadioGroup.Indicator className='radio__group__indicator' />
                </RadioGroup.Item>
                <label className='radio__group__label' htmlFor='r1'>
                  {company.empresas.razao_social}
                </label>
              </div>
            ))}
          </RadioGroup.Root>

          <div
            style={{
              display: 'flex',
              marginTop: 25,
              justifyContent: 'flex-end',
            }}
          >
            <Dialog.Close asChild disabled={!checkboxSelectedCompany}>
              <button
                className={`button ${
                  checkboxSelectedCompany ? 'enable' : 'disable'
                }`}
                onClick={() => close()}
              >
                Confirmar
              </button>
            </Dialog.Close>
          </div>
          <Dialog.Close asChild>
            <button className='icon__button' aria-label='Close'>
              <Cross2Icon />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
