import * as AlertDialog from '@radix-ui/react-alert-dialog'
import { TrashIcon } from '@radix-ui/react-icons'
import './styles.css'

type DeleteConfirmationProps = {
  id: number
  callback: (id: number) => void
  customMessage?: string
}

export default function DeleteConfirmation({ id, callback, customMessage }: DeleteConfirmationProps) {
  return (
    <AlertDialog.Root>
      <div className="delete__confirmation">
        <AlertDialog.Trigger asChild>
          <div className="inactive__button">
            <TrashIcon height="22" width="22" color="white" />
          </div>
        </AlertDialog.Trigger>
        <AlertDialog.Portal>
          <AlertDialog.Overlay className="alert__dialog__overlay" />
          <AlertDialog.Content className="alert__dialog__content">
            <AlertDialog.Title className="alert__dialog__title">Deseja remover esse registro?</AlertDialog.Title>

            {customMessage ? (
              <AlertDialog.Description className="alert__dialog__description">{customMessage}</AlertDialog.Description>
            ) : null}

            <AlertDialog.Description className="alert__dialog__description">
              Você tem certeza? Essa ação não pode ser desfeita, o registro será removido permanentemente.
            </AlertDialog.Description>
            <div style={{ display: 'flex', gap: 15, justifyContent: 'flex-end', paddingTop: 15 }}>
              <AlertDialog.Cancel asChild>
                <button className="button red">Cancelar</button>
              </AlertDialog.Cancel>
              <AlertDialog.Action asChild>
                <button className="button green" onClick={() => callback(id)}>
                  Sim, remover registro
                </button>
              </AlertDialog.Action>
            </div>
          </AlertDialog.Content>
        </AlertDialog.Portal>
      </div>
    </AlertDialog.Root>
  )
}
