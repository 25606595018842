import { useEffect, useState } from 'react'
import { Pagination } from '@mui/material'
import { Heading, Text } from '@radix-ui/themes'
import { api } from '../../services/api'
import { Module } from '../../interfaces/module'

import Navbar from '../../components/navbar'
import Notification from '../../components/notification'

import DeleteConfirmation from '../../components/modals/delete-confirmation'
import CreateModule from '../../components/modals/create-module'

import './styles.css'

type NotificationProps = {
  title: string
  description: string
}

export default function Modules() {
  const COMPONENTS_PER_PAGE = 10

  const [modules, setModules] = useState<Module[]>([])

  const [notificationOpen, setNotificationOpen] = useState<boolean>(false)
  const [notificationProps, setNotificationProps] = useState<NotificationProps>(
    { title: '', description: '' }
  )

  const [loadingModules, setLoadingModules] = useState(false)

  const [pages, setPages] = useState<number>(0)
  const [pageData, setPageData] = useState<any[]>([
    modules.slice(0, COMPONENTS_PER_PAGE),
  ])

  useEffect(() => {
    const storedModules = sessionStorage.getItem('@RURAUTH:modules')
    if (!storedModules) {
      list()
    } else {
      const parsed = JSON.parse(storedModules)
      setModules(parsed)
      setPageData(parsed.slice(0, COMPONENTS_PER_PAGE))
    }
  }, [])

  function handlePaginationChange(event: any, value: number) {
    const startIndex = value * COMPONENTS_PER_PAGE - COMPONENTS_PER_PAGE
    const lastIndex = startIndex + COMPONENTS_PER_PAGE

    if (modules?.length) {
      setPageData(modules.slice(startIndex, lastIndex))
    }
  }

  async function list() {
    setLoadingModules(true)

    const { data } = await api.get('/modulos')

    setModules(data.modulos)
    sessionStorage.setItem('@RURAUTH:modules', JSON.stringify(data.modulos))

    const dataSplited = String(data && data.length / COMPONENTS_PER_PAGE).split(
      '.'
    )

    if (dataSplited[1]) {
      setPages(Number(dataSplited[0]) + 1)
    } else {
      setPages(Number(dataSplited[0]))
    }

    setPageData(data.modulos.slice(0, COMPONENTS_PER_PAGE))

    setLoadingModules(false)
  }

  async function remove(id: number) {
    try {
      await api.delete(`/modulos/${id}`)
      setNotificationProps({
        title: 'Sucesso!',
        description: `Módulo removido com sucesso.`,
      })
      setNotificationOpen(true)
      list()
    } catch (err) {
      setNotificationProps({
        title: 'Erro!',
        description: `Não foi possível remover esse módulo.`,
      })
      setNotificationOpen(true)
    }
  }

  async function create(description: string) {
    const { status } = await api.post('/modulos', { descricao: description })
    if (status === 201) {
      setNotificationProps({
        title: 'Sucesso!',
        description: `Módulo cadastrado com sucesso.`,
      })
      list()
    } else {
      setNotificationProps({
        title: 'Erro!',
        description: `Não foi possível cadastrar o módulo.`,
      })
    }
    setNotificationOpen(true)
  }

  return (
    <div className='main'>
      <Navbar />

      {loadingModules ? (
        <div>carr</div>
      ) : (
        <div className='container modules'>
          <div className='header'>
            <Heading className='title'>Módulos</Heading>
            <Text>Gerencie os módulos cadastrados na plataforma.</Text>
          </div>

          <div className='table__header'>
            <div></div>
            <CreateModule callback={create} />
          </div>

          <div>
            <table>
              <thead>
                <tr>
                  <th align='left'>ID</th>
                  <th align='left'>Descrição</th>
                  <th align='right'></th>
                </tr>
              </thead>

              <tbody>
                {pageData.map((module) => (
                  <tr className='table__row'>
                    <td>{String(module.id).padStart(3, '0')}</td>
                    <td>{module.descricao}</td>
                    <td>{module.cnpj}</td>
                    <td align='right'>
                      <DeleteConfirmation id={module.id} callback={remove} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div className='table__footer'>
              {modules.length > 5 ? (
                <Pagination
                  count={pages}
                  color='primary'
                  variant='outlined'
                  shape='rounded'
                  onChange={handlePaginationChange}
                />
              ) : null}
            </div>

            <Notification
              state={notificationOpen}
              description={notificationProps.description}
              title={notificationProps.title}
              handleState={setNotificationOpen}
            />
          </div>
        </div>
      )}
    </div>
  )
}
