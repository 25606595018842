import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  Cross2Icon,
  PlusIcon,
} from '@radix-ui/react-icons'
import { Button } from '@radix-ui/themes'
import { useEffect, useState } from 'react'
import { Module } from '../../../interfaces/module'
import { api } from '../../../services/api'
import { Company } from '../../../pages/companies'

import Loading from '../../loading'
import * as Select from '@radix-ui/react-select'
import * as Dialog from '@radix-ui/react-dialog'

import './styles.css'

type CreateCompanyModuleRelationProps = {
  callback: (moduleId: number) => void
  company?: Company
}

export default function CreateCompanyModuleRelation({
  callback,
  company,
}: CreateCompanyModuleRelationProps) {
  const [selectedModuleId, setSelectedModuleId] = useState<number | undefined>()

  const [modules, setModules] = useState<Module[]>([])
  const [loadingModules, setLoadingModules] = useState(false)

  useEffect(() => {
    const storedModules = sessionStorage.getItem('@RURAUTH:modules')
    if (!storedModules) {
      list()
    } else {
      setModules(JSON.parse(storedModules))
    }
  }, [])

  function close() {
    setSelectedModuleId(undefined)
    if (selectedModuleId) {
      callback(selectedModuleId)
    }
  }

  async function list() {
    setLoadingModules(true)

    const { data } = await api.get('/modulos')

    sessionStorage.setItem('@RURAUTH:modules', JSON.stringify(data.modulos))

    setModules(data.modulos)
    setLoadingModules(false)
  }

  return (
    <Dialog.Root>
      <Dialog.Trigger asChild>
        <Button className='add__button'>
          <PlusIcon width='22' height='22' color='white' />
        </Button>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className='dialog__overlay' />
        <Dialog.Content className='dialog__content'>
          <Dialog.Title className='dialog__title'>
            Vincular módulo e empresa
          </Dialog.Title>

          <div
            style={{
              display: 'flex',
              marginTop: 25,
              justifyContent: 'space-between',
            }}
          >
            {loadingModules ? (
              <Loading size='small' />
            ) : (
              <Select.Root
                onValueChange={(e) => setSelectedModuleId(Number(e))}
              >
                <Select.Trigger className='select__trigger' aria-label='modulo'>
                  <Select.Value placeholder='Selecione um módulo...' />
                  <Select.Icon className='select__icon'>
                    <ChevronDownIcon />
                  </Select.Icon>
                </Select.Trigger>
                <Select.Portal>
                  <Select.Content className='select__content'>
                    <Select.ScrollUpButton className='select__scroll__button'>
                      <ChevronUpIcon />
                    </Select.ScrollUpButton>
                    <Select.Viewport className='select__viewport'>
                      <Select.Group>
                        <Select.Label className='select__label'>
                          Empresas
                        </Select.Label>
                        {modules.map((module) => (
                          <Select.Item
                            className='select__item'
                            value={String(module.id)}
                          >
                            <Select.ItemText>
                              {module.descricao}
                            </Select.ItemText>
                            <Select.ItemIndicator className='select__item__indicator'>
                              <CheckIcon />
                            </Select.ItemIndicator>
                          </Select.Item>
                        ))}
                      </Select.Group>
                    </Select.Viewport>
                    <Select.ScrollDownButton className='select__scroll__button'>
                      <ChevronDownIcon />
                    </Select.ScrollDownButton>
                  </Select.Content>
                </Select.Portal>
              </Select.Root>
            )}

            <Dialog.Close asChild disabled={!selectedModuleId}>
              <button
                className={`button ${selectedModuleId ? 'enable' : 'disable'}`}
                onClick={() => close()}
              >
                Vincular
              </button>
            </Dialog.Close>
          </div>
          <Dialog.Close asChild>
            <button className='icon__button' aria-label='Close'>
              <Cross2Icon />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
