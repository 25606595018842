import * as Dialog from '@radix-ui/react-dialog'
import { Cross2Icon, LockClosedIcon } from '@radix-ui/react-icons'
import { useState } from 'react'
import Loading from '../../loading'
import './styles.css'

type ChangePasswordProps = {
  callback: (password: string) => void
  waitingRequest: boolean
}

export default function ChangePassword({
  callback,
  waitingRequest,
}: ChangePasswordProps) {
  const [confirmPassword, setConfirmPassword] = useState('')
  const [password, setPassword] = useState('')

  function close() {
    callback(password)
    setConfirmPassword('')
    setPassword('')
  }

  return (
    <Dialog.Root>
      <Dialog.Trigger asChild>
        <div className='dialog__option'>
          <Dialog.Description className='option__description'>
            Alterar senha
          </Dialog.Description>

          {waitingRequest ? <Loading size='small' /> : <LockClosedIcon />}
        </div>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className='dialog__overlay' />
        <Dialog.Content className='dialog__content'>
          <Dialog.Title className='dialog__title'>Alterar senha</Dialog.Title>

          <fieldset className='fieldset' style={{ paddingTop: 20 }}>
            <label className='label' htmlFor='newPassword'>
              Nova senha
            </label>
            <input
              className='input'
              id='newPassword'
              type='password'
              onChange={(e) => setPassword(e.target.value)}
            />
          </fieldset>
          <fieldset className='fieldset' style={{ paddingTop: 20 }}>
            <label className='label' htmlFor='confirmPassword'>
              Confirmar senha
            </label>
            <input
              className='input'
              id='confirmPassword'
              type='password'
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </fieldset>
          <div
            style={{
              display: 'flex',
              marginTop: 25,
              justifyContent: 'flex-end',
            }}
          >
            <Dialog.Close
              asChild
              disabled={!(password.length > 3 && password === confirmPassword)}
            >
              <button
                className={`button ${
                  password.length > 3 && password === confirmPassword
                    ? 'enable'
                    : 'disable'
                }`}
                onClick={() => close()}
              >
                Alterar
              </button>
            </Dialog.Close>
          </div>
          <Dialog.Close asChild>
            <button className='icon__button' aria-label='Close'>
              <Cross2Icon />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
