import { useContext, useEffect, useState } from 'react'
import { Pagination } from '@mui/material'
import { Heading, Text } from '@radix-ui/themes'
import { AuthContext } from '../../contexts/AuthContext'
import { AxiosError } from 'axios'
import { api } from '../../services/api'

import Navbar from '../../components/navbar'
import DeleteConfirmation from '../../components/modals/delete-confirmation'
import Notification from '../../components/notification'
import Loading from '../../components/loading'

import './styles.css'

type Relation = {
  id: number
  nome: string
  email: string
  status: number
  ultimo_acesso: string
}

type NotificationProps = {
  title: string
  description: string
}

export default function CompanyRelations() {
  const { selectedCompany, setSelectedCompany } = useContext(AuthContext)

  const COMPONENTS_PER_PAGE = 5
  const userEmail = sessionStorage.getItem('@RURAUTH:email')

  const [companyRelations, setCompanyRelations] = useState<Relation[]>([])

  const [pages, setPages] = useState<number>(0)
  const [pageData, setPageData] = useState<any[]>([
    companyRelations.slice(0, COMPONENTS_PER_PAGE),
  ])

  const [notificationProps, setNotificationProps] = useState<NotificationProps>(
    { title: '', description: '' }
  )
  const [notificationOpen, setNotificationOpen] = useState<boolean>(false)
  const [loadingRelations, setLoadingRelations] = useState(false)

  useEffect(() => {
    if (selectedCompany) {
      list(selectedCompany.empresas.id)
    }
  }, [selectedCompany])

  function handlePaginationChange(event: any, value: number) {
    const startIndex = value * COMPONENTS_PER_PAGE - COMPONENTS_PER_PAGE
    const lastIndex = startIndex + COMPONENTS_PER_PAGE

    if (companyRelations?.length) {
      setPageData(companyRelations.slice(startIndex, lastIndex))
    }
  }

  async function remove(id: number) {
    try {
      await api.delete(`/empresas/usuarios/${id}`)

      if (selectedCompany) {
        await list(selectedCompany.id)
        setNotificationProps({
          title: 'Sucesso!',
          description: 'Registro removido com sucesso',
        })
        setNotificationOpen(true)
      }
    } catch (err) {
      setNotificationProps({
        title: 'Erro!',
        description:
          err instanceof AxiosError && err.response
            ? err.response.data.error
            : 'Oops, falha na requisição.',
      })
      setNotificationOpen(true)
    }
  }

  async function list(companyId: number) {
    setLoadingRelations(true)

    const { data } = await api.get(`/empresas/${companyId}/usuarios`)
    const users = data.usuarios

    setCompanyRelations(users)

    const dataSplited = String(
      users && users.length / COMPONENTS_PER_PAGE
    ).split('.')

    if (dataSplited[1]) {
      setPages(Number(dataSplited[0]) + 1)
    } else {
      setPages(Number(dataSplited[0]))
    }

    setPageData(users.slice(0, COMPONENTS_PER_PAGE))

    handlePaginationChange(null, 1)

    setLoadingRelations(false)
  }

  return (
    <div className='main'>
      <Navbar />

      {selectedCompany ? (
        loadingRelations ? (
          <div
            className='container'
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Loading size='large' />
          </div>
        ) : pageData.length ? (
          <div className='container company__relations'>
            <div className='header'>
              <div className='page__title'>
                <Heading className='title'>Vínculos Empresariais</Heading>

                <Heading
                  className='company'
                  onClick={() => setSelectedCompany(undefined)}
                >
                  {selectedCompany.empresas.razao_social}
                </Heading>
              </div>
              <Text>
                Gerencie os usuários que são vinculados a sua empresa.
              </Text>
            </div>

            <div>
              <table>
                <thead>
                  <tr>
                    <th align='left'>Nome</th>
                    <th align='left'>Email</th>
                    <th align='left'></th>
                  </tr>
                </thead>

                <tbody>
                  {pageData.map((relation) => (
                    <tr className='table__row'>
                      <td>{relation.usuarios?.nome}</td>
                      <td>{relation.usuarios?.email}</td>
                      <td align='right'>
                        {relation.usuarios?.email !== userEmail ? (
                          <DeleteConfirmation
                            id={relation.id}
                            callback={remove}
                            customMessage='Todos os relacionamentos desse usuários com os módulos da sua empresa também serão removidos.'
                          />
                        ) : null}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className='table__footer'>
                {companyRelations.length > 5 ? (
                  <Pagination
                    count={pages}
                    color='primary'
                    variant='outlined'
                    shape='rounded'
                    onChange={handlePaginationChange}
                  />
                ) : null}
              </div>

              <Notification
                state={notificationOpen}
                description={notificationProps.description}
                title={notificationProps.title}
                handleState={setNotificationOpen}
              />
            </div>
          </div>
        ) : (
          <div>sem dados...</div>
        )
      ) : null}
    </div>
  )
}
