import { useEffect, useState } from 'react'
import { Pagination } from '@mui/material'
import { Heading, Text } from '@radix-ui/themes'
import {
  ArchiveIcon,
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  EnvelopeOpenIcon,
  LayersIcon,
} from '@radix-ui/react-icons'
import { api } from '../../services/api'
import { MagnifyingGlassIcon } from '@radix-ui/react-icons'
import { Company } from '../companies'
import { Button } from '@radix-ui/themes'
import { AxiosError } from 'axios'

import * as Select from '@radix-ui/react-select'
import Navbar from '../../components/navbar'
import DeleteConfirmation from '../../components/modals/delete-confirmation'
import Notification from '../../components/notification'
import ChangeStatusConfirmation from '../../components/modals/change-status-confirmation'
import CreateCompanyModuleRelation from '../../components/modals/create-company-module-relation'
import Loading from '../../components/loading'

import './styles.css'

type Relation = {
  id: number
  id_modulo: number
  id_empresa: number
  id_usuario: number
  status: number
  ultimo_acesso: string
  usuarios: {
    nome: string
    email: string
  }
}

type NotificationProps = {
  title: string
  description: string
}

export default function CompanyModules() {
  const COMPONENTS_PER_PAGE = 5

  const [companies, setCompanies] = useState<Company[]>([])
  const [companyModules, setCompanyModules] = useState<Relation[]>([])
  const [selectedCompanyId, setSelectedCompanyId] = useState<number | null>(
    null
  )

  const [notificationOpen, setNotificationOpen] = useState<boolean>(false)
  const [notificationProps, setNotificationProps] = useState<NotificationProps>(
    { title: '', description: '' }
  )

  const [waitingRequest, setWaitingRequest] = useState(false)

  useEffect(() => {
    listCompanies()
  }, [])

  const [pages, setPages] = useState<number>(0)
  const [pageData, setPageData] = useState<any[]>([
    companyModules.slice(0, COMPONENTS_PER_PAGE),
  ])

  function handlePaginationChange(event: any, value: number) {
    const startIndex = value * COMPONENTS_PER_PAGE - COMPONENTS_PER_PAGE
    const lastIndex = startIndex + COMPONENTS_PER_PAGE

    if (companyModules?.length) {
      setPageData(companyModules.slice(startIndex, lastIndex))
    }
  }

  async function bind(moduleId: number) {
    try {
      await api.post('/empresas/modulos', {
        id_empresa: selectedCompanyId,
        id_modulo: moduleId,
      })
      listCompanyModules()
    } catch (err) {
      setNotificationOpen(true)
      setNotificationProps({
        title: 'Erro!',
        description:
          err instanceof AxiosError && err.response
            ? err.response.data.error
            : `Oops, houve um erro ao realizar o vínculo.`,
      })
    }
  }

  async function remove(id: number) {
    try {
      await api.delete(`/empresas/modulos/${id}`)
      await listCompanyModules()
    } catch (err) {
      setNotificationOpen(true)
      setNotificationProps({
        title: 'Erro!',
        description:
          err instanceof AxiosError && err.response
            ? err.response.data.error
            : `Falha ao remover o vínculo.`,
      })
    }
  }

  async function listCompanyModules() {
    setWaitingRequest(true)

    try {
      const { data } = await api.get(`/empresas/${selectedCompanyId}/modulos`)
      const { modulos: modules } = data

      const dataSplited = String(modules.length / COMPONENTS_PER_PAGE).split(
        '.'
      )

      if (dataSplited[1]) {
        setPages(Number(dataSplited[0]) + 1)
      } else {
        setPages(Number(dataSplited[0]))
      }

      setTimeout(() => {
        setCompanyModules(modules)
        setPageData(modules.slice(0, COMPONENTS_PER_PAGE))
        setWaitingRequest(false)
      }, 500)
    } catch (err) {
      setNotificationProps({
        title: 'Erro!',
        description: 'Oops, não foi possível listar os módulos da empresa.',
      })
      setNotificationOpen(true)
    }
  }

  async function listCompanies() {
    try {
      const { data } = await api.get(`/empresas`)

      setCompanies(data.empresas)
    } catch (err) {
      setNotificationProps({
        title: 'Erro!',
        description: 'Oops, não foi possível listar as empresas.',
      })
      setNotificationOpen(true)
    }
  }

  async function changeCompanyModuleStatus(
    relationId: number,
    relationStatus: number
  ) {
    try {
      await api.put(
        `/empresas/modulos/vinculos/${relationId}/${
          !!relationStatus ? 'desativar' : 'ativar'
        }`
      )

      await listCompanyModules()
    } catch (err) {
      setNotificationProps({
        title: 'Erro!',
        description: `Oops, não foi possível ${
          !!relationStatus ? 'desabilitar' : 'habilitar'
        } o módulo para essa empresa.`,
      })
      setNotificationOpen(true)
    }
  }

  return (
    <div className='main'>
      <Navbar />

      <div className='container company__modules'>
        <div className='header'>
          <Heading className='title'>Módulos Empresas</Heading>
          <Text>Gerencie quais módulos são liberados por empresa.</Text>
        </div>

        <div className='table__header'>
          <div className='filter__row'>
            <Select.Root onValueChange={(e) => setSelectedCompanyId(Number(e))}>
              <Select.Trigger className='select__trigger' aria-label='empresa'>
                <Select.Value placeholder='Selecione uma empresa...' />
                <Select.Icon className='select__icon'>
                  <ChevronDownIcon />
                </Select.Icon>
              </Select.Trigger>
              <Select.Portal>
                <Select.Content className='select__content'>
                  <Select.ScrollUpButton className='select__scroll__button'>
                    <ChevronUpIcon />
                  </Select.ScrollUpButton>
                  <Select.Viewport className='select__viewport'>
                    <Select.Group>
                      <Select.Label className='select__label'>
                        Empresas
                      </Select.Label>
                      {companies.map((company) => (
                        <Select.Item
                          className='select__item'
                          value={String(company.id)}
                        >
                          <Select.ItemText>
                            {company.razao_social}
                          </Select.ItemText>
                          <Select.ItemIndicator className='select__item__indicator'>
                            <CheckIcon />
                          </Select.ItemIndicator>
                        </Select.Item>
                      ))}
                    </Select.Group>
                  </Select.Viewport>
                  <Select.ScrollDownButton className='select__scroll__button'>
                    <ChevronDownIcon />
                  </Select.ScrollDownButton>
                </Select.Content>
              </Select.Portal>
            </Select.Root>

            <Button
              className='filter__button'
              onClick={() => listCompanyModules()}
            >
              <MagnifyingGlassIcon width='22' height='22' color='white' />
            </Button>
          </div>

          <CreateCompanyModuleRelation
            callback={bind}
            company={companies.find((c) => c.id === selectedCompanyId)}
          />
        </div>

        <div>
          <table>
            {waitingRequest ? (
              <div className='loading__box'>
                <Loading size='medium' />
              </div>
            ) : companyModules.length ? (
              <>
                <thead>
                  <tr>
                    <th align='left'>Descrição</th>
                    <th align='center'>Status</th>
                    <th align='left'></th>
                  </tr>
                </thead>

                <tbody>
                  {pageData.map((relation) => (
                    <tr className='table__row'>
                      <td>{relation.modulos.descricao}</td>
                      <td align='center'>
                        <ChangeStatusConfirmation
                          id={relation.id}
                          callback={() =>
                            changeCompanyModuleStatus(
                              relation.id,
                              relation.status
                            )
                          }
                          isEnabled={relation.status}
                        />
                      </td>
                      <td align='right'>
                        <DeleteConfirmation
                          id={relation.id}
                          callback={remove}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </>
            ) : (
              <div className='no__data__found'>
                <span>não há dados, que tal selecionar outra empresa?</span>
              </div>
            )}
          </table>

          <div className='table__footer'>
            {companyModules.length > 5 ? (
              <Pagination
                count={pages}
                color='primary'
                variant='outlined'
                shape='rounded'
                onChange={handlePaginationChange}
              />
            ) : null}
          </div>

          <Notification
            state={notificationOpen}
            description={notificationProps.description}
            title={notificationProps.title}
            handleState={setNotificationOpen}
          />
        </div>
      </div>
    </div>
  )
}
