import { CaretDownIcon } from '@radix-ui/react-icons'
import { ExitIcon } from '@radix-ui/react-icons'
import { useContext } from 'react'
import { AuthContext } from '../../contexts/AuthContext'

import * as NavigationMenu from '@radix-ui/react-navigation-menu'
import Profile from '../modals/profile'
import CompanyManagementSelection from '../modals/company-management-selection'

import './styles.css'

export default function Navbar() {
  const role = sessionStorage.getItem('@RURAUTH:role')
  const { logout } = useContext(AuthContext)

  return (
    <>
      <NavigationMenu.Root className='navigation__menu__root'>
        <NavigationMenu.List className='navigation__menu__list'>
          <NavigationMenu.Item>
            <NavigationMenu.Link className='navigation__menu__link' href=''>
              Home
            </NavigationMenu.Link>
          </NavigationMenu.Item>

          {role === 'comum' ? (
            <NavigationMenu.Item>
              <NavigationMenu.Trigger className='navigation__menu__trigger'>
                Vínculos <CaretDownIcon className='CaretDown' aria-hidden />
              </NavigationMenu.Trigger>
              <NavigationMenu.Content className='navigation__menu__content'>
                <ul className='list two'>
                  <li>
                    <NavigationMenu.Link asChild>
                      <a
                        className='list__item__link'
                        href='/relations/companies'
                      >
                        <div className='list__item__heading'>
                          Vínculos Empresariais
                        </div>
                        <p className='list__item__text'>
                          Gerencie os usuários que são vinculados com a sua
                          empresa.
                        </p>
                      </a>
                    </NavigationMenu.Link>
                  </li>

                  <li>
                    <NavigationMenu.Link asChild>
                      <a className='list__item__link' href='/relations/modules'>
                        <div className='list__item__heading'>
                          Vínculos de Módulos
                        </div>
                        <p className='list__item__text'>
                          Gerencie vínculos dos usuários aos seus módulos.
                        </p>
                      </a>
                    </NavigationMenu.Link>
                  </li>
                </ul>
              </NavigationMenu.Content>
            </NavigationMenu.Item>
          ) : null}

          {role === 'admin' ? (
            <NavigationMenu.Item>
              <NavigationMenu.Trigger className='navigation__menu__trigger'>
                Gerenciamento{' '}
                <CaretDownIcon className='CaretDown' aria-hidden />
              </NavigationMenu.Trigger>
              <NavigationMenu.Content className='navigation__menu__content'>
                <ul className='list two'>
                  <li>
                    <NavigationMenu.Link asChild>
                      <a className='list__item__link' href='/companies'>
                        <div className='list__item__heading'>Empresas</div>
                        <p className='list__item__text'>
                          Visualize quais empresas estão cadastradas no rurauth.
                        </p>
                      </a>
                    </NavigationMenu.Link>
                  </li>

                  <li>
                    <NavigationMenu.Link asChild>
                      <a className='list__item__link' href='/modules'>
                        <div className='list__item__heading'>Módulos</div>
                        <p className='list__item__text'>
                          Gerencie os módulos cadastrados na plataforma.
                        </p>
                      </a>
                    </NavigationMenu.Link>
                  </li>

                  <li>
                    <NavigationMenu.Link asChild>
                      <a
                        className='list__item__link'
                        href='/relations/modules/companies'
                      >
                        <div className='list__item__heading'>
                          Módulos Empresas
                        </div>
                        <p className='list__item__text'>
                          Gerencie quais módulos são liberados por empresa.
                        </p>
                      </a>
                    </NavigationMenu.Link>
                  </li>

                  <li>
                    <NavigationMenu.Link asChild>
                      <a className='list__item__link' href='/users'>
                        <div className='list__item__heading'>Usuários</div>
                        <p className='list__item__text'>
                          Visualize os usuários que possuem acesso ao painel de
                          administração.
                        </p>
                      </a>
                    </NavigationMenu.Link>
                  </li>

                  <li>
                    <NavigationMenu.Link asChild>
                      <a className='list__item__link' href='/companies/admins'>
                        <div className='list__item__heading'>
                          Administradores de Empresas
                        </div>
                        <p className='list__item__text'>
                          Gerencie quais são os administradores das empresas
                          cadastradas.
                        </p>
                      </a>
                    </NavigationMenu.Link>
                  </li>
                </ul>
              </NavigationMenu.Content>
            </NavigationMenu.Item>
          ) : null}

          {role === 'comum' ? <Profile /> : null}

          <NavigationMenu.Item onClick={() => logout()}>
            <NavigationMenu.Link className='navigation__menu__link' href=''>
              <ExitIcon />
            </NavigationMenu.Link>
          </NavigationMenu.Item>
        </NavigationMenu.List>

        <div className='viewport__position'>
          <NavigationMenu.Viewport className='navigation__menu__viewport' />
        </div>
      </NavigationMenu.Root>

      <CompanyManagementSelection />
    </>
  )
}
