import { useState } from 'react'
import { Cross2Icon, PlusIcon } from '@radix-ui/react-icons'
import { Button } from '@radix-ui/themes'

import * as Dialog from '@radix-ui/react-dialog'

import './styles.css'

type InviteUserProps = {
  callback: (email: string) => void
}

export default function InviteUser({ callback }: InviteUserProps) {
  const [email, setEmail] = useState('')

  function close() {
    setEmail('')
    callback(email)
  }

  return (
    <Dialog.Root>
      <Dialog.Trigger asChild>
        <Button className="add__button">
          <PlusIcon width="22" height="22" color="white" />
        </Button>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="dialog__overlay" />
        <Dialog.Content className="dialog__content">
          <Dialog.Title className="dialog__title">Convidar usuário</Dialog.Title>
          <Dialog.Description className="dialog__description">
            Após clicar em gerar link o convite será copiado automaticamente para área de transferência.
          </Dialog.Description>
          <fieldset className="fieldset" style={{ paddingTop: 20 }}>
            <label className="label" htmlFor="email">
              Email
            </label>
            <input className="input" id="email" type="email" required onChange={(e) => setEmail(e.target.value)} />
          </fieldset>
          <div style={{ display: 'flex', marginTop: 25, justifyContent: 'flex-end' }}>
            <Dialog.Close asChild disabled={!(email.length > 10 && email.includes('@'))}>
              <button
                className={`button ${email.length > 10 && email.includes('@') ? 'enable' : 'disable'}`}
                onClick={() => close()}
              >
                Gerar link
              </button>
            </Dialog.Close>
          </div>
          <Dialog.Close asChild>
            <button className="icon__button" aria-label="Close">
              <Cross2Icon />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
