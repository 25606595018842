import * as React from 'react'
import * as Toast from '@radix-ui/react-toast'
import './styles.css'

type NotificationProps = {
  state: boolean
  title: string
  description: string
  handleState: React.Dispatch<React.SetStateAction<boolean>>
}

export default function Notification({
  state,
  title,
  description,
  handleState,
}: NotificationProps) {
  React.useEffect(() => {
    handleState(state)

    setTimeout(() => {
      return () => handleState(false)
    }, 5000)
  }, [state])

  return (
    <Toast.Provider swipeDirection='right'>
      <Toast.Root className='ToastRoot' open={state} onOpenChange={handleState}>
        <Toast.Title className='ToastTitle'>{title}</Toast.Title>
        <Toast.Description asChild>
          <time className='ToastDescription'>{description}</time>
        </Toast.Description>
      </Toast.Root>
      <Toast.Viewport className='ToastViewport' />
    </Toast.Provider>
  )
}
