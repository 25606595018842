import React from 'react'
import ReactDOM from 'react-dom/client'
import Router from './router'

import { AuthProvider } from './contexts/AuthContext'

import '@radix-ui/themes/styles.css'
import './assets/styles/globals.css'
import './index.css'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <AuthProvider>
    <Router />
  </AuthProvider>
)
