import { useEffect, useState, useContext } from 'react'
import { Pagination } from '@mui/material'
import { Heading, Text } from '@radix-ui/themes'
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from '@radix-ui/react-icons'
import { AuthContext } from '../../contexts/AuthContext'
import { api } from '../../services/api'
import { MagnifyingGlassIcon } from '@radix-ui/react-icons'
import { Button } from '@radix-ui/themes'
import { AxiosError } from 'axios'

import Navbar from '../../components/navbar'
import DeleteConfirmation from '../../components/modals/delete-confirmation'
import InviteUser from '../../components/modals/invite-user'
import Notification from '../../components/notification'
import * as Select from '@radix-ui/react-select'
import ChangeStatusConfirmation from '../../components/modals/change-status-confirmation'
import Loading from '../../components/loading'

import './styles.css'

type Relation = {
  id: number
  id_modulo: number
  id_empresa: number
  id_usuario: number
  status: number
  ultimo_acesso: string
  usuarios: {
    nome: string
    email: string
  }
}

type NotificationProps = {
  title: string
  description: string
}

type Module = {
  id: number
  modulos: {
    id: number
    descricao: string
  }
}

export default function ModulesRelations() {
  const { selectedCompany, setSelectedCompany } = useContext(AuthContext)

  const COMPONENTS_PER_PAGE = 5

  const [relations, setRelations] = useState<Relation[]>([])
  const [modules, setModules] = useState<Module[]>([])

  const [notificationProps, setNotificationProps] = useState<NotificationProps>(
    { title: '', description: '' }
  )
  const [notificationOpen, setNotificationOpen] = useState<boolean>(false)

  const [selectedModuleId, setSelectedModuleId] = useState<number | null>(null)

  const [loadingRelations, setLoadingRelations] = useState(false)

  useEffect(() => {
    listModules()
  }, [])

  const [pages, setPages] = useState<number>(0)
  const [pageData, setPageData] = useState<any[]>([
    relations.slice(0, COMPONENTS_PER_PAGE),
  ])

  function handlePaginationChange(event: any, value: number) {
    const startIndex = value * COMPONENTS_PER_PAGE - COMPONENTS_PER_PAGE
    const lastIndex = startIndex + COMPONENTS_PER_PAGE

    if (relations?.length) {
      setPageData(relations.slice(startIndex, lastIndex))
    }
  }

  async function remove(id: number) {
    try {
      await api.delete(`/empresas/modulos/usuarios/${id}`)
      await list()
    } catch (err) {
      setNotificationProps({
        title: 'Erro!',
        description:
          err instanceof AxiosError && err.response
            ? err.response.data.error
            : `Oops, falha ao remover o vínculo.`,
      })
      setNotificationOpen(true)
    }
  }

  async function updateRelationStatus(
    relationId: number,
    status: 'enable' | 'disable'
  ) {
    try {
      await api.put(
        `/modulos/usuarios/${
          status === 'enable' ? 'ativar' : 'desativar'
        }/${relationId}`
      )
      await list()
    } catch (err) {
      setNotificationProps({
        title: 'Erro!',
        description:
          err instanceof AxiosError && err.response
            ? err.response.data.error
            : `Oops, falha ao ${
                status === 'disable' ? 'desativar' : 'ativar'
              } o vínculo.`,
      })
      setNotificationOpen(true)
    }
  }

  async function generateInvite(email: string) {
    if (selectedCompany) {
      try {
        const { data } = await api.post('/modulos/convite/gerar', {
          email,
          id_modulo: selectedModuleId,
          cnpj: selectedCompany.empresas.cnpj,
        })

        setNotificationProps({
          title: 'Sucesso!',
          description:
            'Excelente, o link foi enviado por e-mail para o colaborador.',
        })
        setNotificationOpen(true)
        navigator.clipboard.writeText(data.link)
      } catch (err) {
        setNotificationProps({
          title: 'Erro!',
          description:
            err instanceof AxiosError && err.response
              ? err.response.data.error
              : 'Oops, não foi possível gerar um link de convite.',
        })
        setNotificationOpen(true)
      }
    } else {
      setNotificationProps({
        title: 'Erro!',
        description: 'Não foi possível gerar um link de convite.',
      })
      setNotificationOpen(true)
    }
  }

  async function list() {
    if (selectedCompany) {
      try {
        setLoadingRelations(true)

        const { data } = await api.get(
          `/empresas/${selectedCompany.empresas.id}/usuarios/modulos/${selectedModuleId}`
        )
        const users = data.usuarios
        const dataSplited = String(
          users && users.length / COMPONENTS_PER_PAGE
        ).split('.')

        if (dataSplited[1]) {
          setPages(Number(dataSplited[0]) + 1)
        } else {
          setPages(Number(dataSplited[0]))
        }

        setRelations(users)
        setPageData(users.slice(0, COMPONENTS_PER_PAGE))
        setLoadingRelations(false)
      } catch (err) {
        setNotificationProps({
          title: 'Erro!',
          description: 'Não foi possível listar os módulos da empresa',
        })
        setNotificationOpen(true)
      }
    }
  }

  async function listModules() {
    if (selectedCompany) {
      try {
        const { data } = await api.get(
          `/empresas/${selectedCompany.empresas.id}/modulos`
        )
        const modules = data.modulos

        setModules(modules)
      } catch (err) {
        setNotificationProps({
          title: 'Erro!',
          description: 'Não foi possível gerar um link de convite.',
        })
        setNotificationOpen(true)
      }
    }
  }

  return (
    <div className='main'>
      <Navbar />

      <div className='container modules__relations'>
        <div className='header'>
          <div className='page__title'>
            <Heading className='title'>Vínculos de Módulos</Heading>

            <Heading
              className='company'
              onClick={() => setSelectedCompany(undefined)}
            >
              {selectedCompany?.empresas.razao_social}
            </Heading>
          </div>
          <Text>Gerencie e convide novos usuários para os seus sistemas.</Text>
        </div>

        <div className='table__header'>
          <div className='filter__row'>
            <Select.Root onValueChange={(e) => setSelectedModuleId(Number(e))}>
              <Select.Trigger className='select__trigger' aria-label='sistema'>
                <Select.Value placeholder='Selecione um sistema...' />
                <Select.Icon className='select__icon'>
                  <ChevronDownIcon />
                </Select.Icon>
              </Select.Trigger>
              <Select.Portal>
                <Select.Content className='select__content'>
                  <Select.ScrollUpButton className='select__scroll__button'>
                    <ChevronUpIcon />
                  </Select.ScrollUpButton>
                  <Select.Viewport className='select__viewport'>
                    <Select.Group>
                      <Select.Label className='select__label'>
                        Sistemas
                      </Select.Label>
                      {modules.map((module) => (
                        <Select.Item
                          className='select__item'
                          value={String(module.modulos.id)}
                        >
                          <Select.ItemText>
                            {module.modulos.descricao}
                          </Select.ItemText>
                          <Select.ItemIndicator className='select__item__indicator'>
                            <CheckIcon />
                          </Select.ItemIndicator>
                        </Select.Item>
                      ))}
                    </Select.Group>
                  </Select.Viewport>
                  <Select.ScrollDownButton className='select__scroll__button'>
                    <ChevronDownIcon />
                  </Select.ScrollDownButton>
                </Select.Content>
              </Select.Portal>
            </Select.Root>

            <Button className='filter__button' onClick={() => list()}>
              <MagnifyingGlassIcon width='22' height='22' color='white' />
            </Button>
          </div>

          {selectedModuleId ? <InviteUser callback={generateInvite} /> : null}
        </div>

        <div>
          <table>
            {loadingRelations ? (
              <div className='loading__box'>
                <Loading size='medium' />
              </div>
            ) : relations.length ? (
              <>
                <thead>
                  <tr>
                    <th align='left'>Nome</th>
                    <th align='left'>Email</th>
                    <th align='center'>Vínculo</th>
                    <th align='left'>Último acesso</th>
                    <th align='left'></th>
                  </tr>
                </thead>

                <tbody>
                  {pageData.map((relation) => (
                    <tr className='table__row'>
                      <td>{relation.usuarios.nome}</td>
                      <td>{relation.usuarios.email}</td>
                      <td align='center'>
                        <ChangeStatusConfirmation
                          id={relation.id}
                          callback={updateRelationStatus}
                          isEnabled={relation.status}
                        />
                      </td>
                      <td>
                        {new Intl.DateTimeFormat('pt-BR', {
                          day: '2-digit',
                          month: '2-digit',
                          year: 'numeric',
                          hour: 'numeric',
                          minute: 'numeric',
                        }).format(new Date(relation.usuarios.ultimo_acesso)) ||
                          '-'}
                      </td>
                      <td align='center'>
                        <DeleteConfirmation
                          id={relation.id}
                          callback={remove}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </>
            ) : (
              <div className='no__data__found'>
                <span>não há dados, que tal selecionar outro módulo?</span>
              </div>
            )}
          </table>

          <div className='table__footer'>
            {relations.length > 5 ? (
              <Pagination
                count={pages}
                color='primary'
                variant='outlined'
                shape='rounded'
                onChange={handlePaginationChange}
              />
            ) : null}
          </div>

          <Notification
            state={notificationOpen}
            description={notificationProps.description}
            title={notificationProps.title}
            handleState={setNotificationOpen}
          />
        </div>
      </div>
    </div>
  )
}
