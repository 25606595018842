import { ArrowLeftIcon } from '@radix-ui/react-icons'
import rurauth from '../../assets/images/rurauth.png'

import './styles.css'

export default function EmailSent() {
  return (
    <div className='email__sent'>
      <img src={rurauth} alt='ruralhub' className='logo__app' />

      <h1>enviaremos um e-mail, verifique a sua caixa de entrada.</h1>

      <div
        className='back__button'
        onClick={() => (window.location.href = '/')}
      >
        <ArrowLeftIcon width='24' height='24' color='white' />
      </div>
    </div>
  )
}
