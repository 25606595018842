import { useEffect, useState } from 'react'
import { Pagination } from '@mui/material'
import { Heading, Text } from '@radix-ui/themes'

import Navbar from '../../components/navbar'
import Notification from '../../components/notification'
import ChangeStatusConfirmation from '../../components/modals/change-status-confirmation'

import './styles.css'
import { api } from '../../services/api'
import { AxiosError } from 'axios'

export type Company = {
  id: number
  cnpj: string
  razao_social: string
  status: number
}

type NotificationProps = {
  title: string
  description: string
}

export default function Companies() {
  const COMPONENTS_PER_PAGE = 10

  const [companies, setCompanies] = useState<Company[]>([])

  const [notificationProps, setNotificationProps] = useState<NotificationProps>(
    { title: '', description: '' }
  )
  const [notificationOpen, setNotificationOpen] = useState<boolean>(false)

  useEffect(() => {
    listCompanies()
  }, [])

  const [pages, setPages] = useState<number>(0)
  const [pageData, setPageData] = useState<any[]>([
    companies.slice(0, COMPONENTS_PER_PAGE),
  ])

  function handlePaginationChange(event: any, value: number) {
    const startIndex = value * COMPONENTS_PER_PAGE - COMPONENTS_PER_PAGE
    const lastIndex = startIndex + COMPONENTS_PER_PAGE

    if (companies?.length) {
      setPageData(companies.slice(startIndex, lastIndex))
    }
  }

  async function listCompanies() {
    try {
      const { data } = await api.get('/empresas')
      const { empresas } = data

      setCompanies(empresas)

      const dataSplited = String(
        empresas && empresas.length / COMPONENTS_PER_PAGE
      ).split('.')

      if (dataSplited[1]) {
        setPages(Number(dataSplited[0]) + 1)
      } else {
        setPages(Number(dataSplited[0]))
      }

      setPageData(empresas.slice(0, COMPONENTS_PER_PAGE))
    } catch (err) {
      setNotificationProps({
        title: 'Erro!',
        description:
          err instanceof AxiosError && err.response
            ? err.response.data.error
            : `Falha ao listar as empresas.`,
      })
      setNotificationOpen(true)
    }
  }

  async function updateCompanyStatus(id: any, status: 'enable' | 'disable') {
    try {
      await api.put(
        `/empresas/${id}/${status === 'enable' ? 'ativar' : 'desativar'}`
      )
      await listCompanies()
    } catch (err) {
      setNotificationProps({
        title: 'Erro!',
        description:
          err instanceof AxiosError && err.response
            ? err.response.data.error
            : `Falha ao ${
                status === 'disable' ? 'desativar' : 'ativar'
              } o vínculo.`,
      })
      setNotificationOpen(true)
    }
  }

  return (
    <div className='main'>
      <Navbar />

      <div className='container companies'>
        <div className='header'>
          <Heading className='title'>Empresas</Heading>
          <Text>Visualize quais empresas estão cadastradas no rurauth.</Text>
        </div>

        <div>
          <table>
            <thead>
              <tr>
                <th align='left'>ID</th>
                <th align='left'>Razão Social</th>
                <th align='left'>CNPJ</th>
                <th align='right'>Status</th>
              </tr>
            </thead>

            <tbody>
              {pageData.map((company) => (
                <tr className='table__row'>
                  <td>{String(company.id).padStart(3, '0')}</td>
                  <td>{company.razao_social}</td>
                  <td>{company.cnpj}</td>
                  <td align='right'>
                    <ChangeStatusConfirmation
                      id={company.id}
                      callback={updateCompanyStatus}
                      isEnabled={company.status}
                    ></ChangeStatusConfirmation>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className='table__footer'>
            {companies.length > 5 ? (
              <Pagination
                count={pages}
                color='primary'
                variant='outlined'
                shape='rounded'
                onChange={handlePaginationChange}
              />
            ) : null}
          </div>

          <Notification
            state={notificationOpen}
            description={notificationProps.description}
            title={notificationProps.title}
            handleState={setNotificationOpen}
          />
        </div>
      </div>
    </div>
  )
}
