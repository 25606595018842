import { useEffect, useState } from 'react'
import { api } from '../../services/api'
import { ArrowLeftIcon } from '@radix-ui/react-icons'

import rurauth from '../../assets/images/rurauth.png'
import Loading from '../../components/loading'

import './styles.css'

export default function ConfirmAccount() {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const userTag = urlParams.get('t')

  const [waitingRequest, setWaitingRequest] = useState(true)
  const [accountConfirmed, setAccountConfirmed] = useState(false)

  useEffect(() => {
    confirmAccount()
  }, [])

  async function confirmAccount() {
    try {
      await api.post(`/usuarios/confirmar-conta`, {
        tag: userTag,
      })

      setAccountConfirmed(true)
      setWaitingRequest(false)
    } catch (err) {
      setAccountConfirmed(false)
      setWaitingRequest(false)
    }
  }

  return (
    <div className='confirm__account'>
      <img src={rurauth} alt='ruralhub' className='logo__app' />

      {waitingRequest ? (
        <Loading size='medium' />
      ) : accountConfirmed ? (
        <div className='steps'>
          <h1>conta confirmada, siga as instruções abaixo.</h1>
          <div className='step__option'>
            <div className='user__type'>
              <h3>administradores</h3>
              <hr />
            </div>
            <p>
              entre em contato com o nosso{' '}
              <a
                target='_blank'
                href={`https://wa.me/556696244033?text=Olá, gostaria de solicitar a liberação do meu usuário no rurauth.`}
              >
                suporte
              </a>{' '}
              e solicite liberação.
            </p>
          </div>

          <div className='step__option'>
            <div className='user__type'>
              <h3>colaboradores</h3>
              <hr />
            </div>
            <p>solicite um link de convite para a sua empresa.</p>
          </div>
        </div>
      ) : (
        <h1>oops, não foi possível confirmar a sua conta, tente novamente.</h1>
      )}

      <div
        className='back__button'
        onClick={() => (window.location.href = '/')}
      >
        <ArrowLeftIcon width='24' height='24' color='white' />
      </div>
    </div>
  )
}
