import { useEffect, useState } from 'react'
import { Pagination } from '@mui/material'
import { Heading, Text, TextField } from '@radix-ui/themes'
import { MagnifyingGlassIcon } from '@radix-ui/react-icons'
import { AxiosError } from 'axios'
import { api } from '../../services/api'

import Navbar from '../../components/navbar'
import Notification from '../../components/notification'

import './styles.css'

export type User = {
  id: number
  nome: string
  email: string
  verificado: number
}

type NotificationProps = {
  title: string
  description: string
}

export default function Users() {
  const COMPONENTS_PER_PAGE = 10

  const [users, setUsers] = useState<User[]>([])

  const [notificationProps, setNotificationProps] = useState<NotificationProps>(
    { title: '', description: '' }
  )
  const [notificationOpen, setNotificationOpen] = useState<boolean>(false)

  useEffect(() => {
    listUsers()
  }, [])

  const [pages, setPages] = useState<number>(0)
  const [pageData, setPageData] = useState<any[]>([
    users.slice(0, COMPONENTS_PER_PAGE),
  ])

  function handlePaginationChange(event: any, value: number) {
    const startIndex = value * COMPONENTS_PER_PAGE - COMPONENTS_PER_PAGE
    const lastIndex = startIndex + COMPONENTS_PER_PAGE

    if (users?.length) {
      setPageData(users.slice(startIndex, lastIndex))
    }
  }

  async function listUsers() {
    try {
      const { data } = await api.get('/usuarios')
      const { usuarios } = data

      setUsers(usuarios)

      const dataSplited = String(
        usuarios && usuarios.length / COMPONENTS_PER_PAGE
      ).split('.')

      if (dataSplited[1]) {
        setPages(Number(dataSplited[0]) + 1)
      } else {
        setPages(Number(dataSplited[0]))
      }

      setPageData(usuarios.slice(0, COMPONENTS_PER_PAGE))

      handlePaginationChange(null, 1)
    } catch (err) {
      setNotificationProps({
        title: 'Erro!',
        description:
          err instanceof AxiosError && err.response
            ? err.response.data.error
            : 'Oops, não foi possível listar os usuários.',
      })
      setNotificationOpen(true)
    }
  }

  async function searchUser(param: string) {
    if (param.length < 3 || !param) {
      return setPageData(users.slice(0, COMPONENTS_PER_PAGE))
    }

    const similarOptions = users.filter(
      (user) =>
        user.nome.toLowerCase().includes(param) ||
        user.email.toLowerCase().includes(param)
    )

    setPageData(similarOptions)
  }
  return (
    <div className='main'>
      <Navbar />

      <div className='container users'>
        <div className='header'>
          <Heading className='title'>Usuários</Heading>
          <Text className='subtitle'>
            Visualize os usuários cadastrados na plataforma.
          </Text>
        </div>

        <TextField.Root className='input__search__user'>
          <TextField.Slot className='input__icon'>
            <MagnifyingGlassIcon height='16' width='16' />
          </TextField.Slot>
          <TextField.Input
            placeholder='Pesquisar cliente...'
            onChange={(e) => searchUser(e.target.value)}
          />
        </TextField.Root>

        <div>
          <table>
            <thead>
              <tr>
                <th align='left'>ID</th>
                <th align='left'>Nome</th>
                <th align='left'>Email</th>
                <th align='center'>Verificado</th>
                <th align='right'>Último acesso</th>
              </tr>
            </thead>

            <tbody>
              {pageData.map((user) => (
                <tr className='table__row'>
                  <td>{String(user.id).padStart(3, '0')}</td>
                  <td>{user.nome}</td>
                  <td>{user.email}</td>
                  <td align='center'>
                    {user.verificado === 1 ? (
                      <div className='active__button'>
                        <span>VERIFICADO</span>
                      </div>
                    ) : (
                      <div className='inactive__button'>
                        <span>PENDENTE</span>
                      </div>
                    )}
                  </td>
                  <td align='right'>{user.ultimo_acesso || '-'}</td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className='table__footer'>
            {users.length > 5 ? (
              <Pagination
                count={pages}
                color='primary'
                variant='outlined'
                shape='rounded'
                onChange={handlePaginationChange}
              />
            ) : null}
          </div>

          <Notification
            state={notificationOpen}
            description={notificationProps.description}
            title={notificationProps.title}
            handleState={setNotificationOpen}
          />
        </div>
      </div>
    </div>
  )
}
