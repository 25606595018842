import { ArrowLeftIcon, CheckIcon } from '@radix-ui/react-icons'
import rurauth from '../../assets/images/rurauth.png'

import './styles.css'

export default function AccountCreated() {
  return (
    <div className='account__created'>
      <img src={rurauth} alt='ruralhub' className='logo__app' />

      <h1>enviamos um e-mail para você, basta confirmar sua conta.</h1>

      <div
        className='back__button'
        onClick={() => (window.location.href = '/')}
      >
        <ArrowLeftIcon width='24' height='24' color='white' />
      </div>
    </div>
  )
}
