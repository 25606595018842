import * as AlertDialog from '@radix-ui/react-alert-dialog'

import './styles.css'

type ChangeStatusConfirmationProps = {
  id: number
  isEnabled: number
  callback: (id: number, status: 'enable' | 'disable') => void
}

export default function ChangeStatusConfirmation({ id, isEnabled, callback }: ChangeStatusConfirmationProps) {
  return (
    <AlertDialog.Root>
      <div className="change__status__confirmation">
        <AlertDialog.Trigger asChild>
          {isEnabled === 1 ? (
            <div className="active__button">
              <span>HABILITADO</span>
            </div>
          ) : (
            <div className="inactive__button">
              <span>DESABILITADO</span>
            </div>
          )}
        </AlertDialog.Trigger>
        <AlertDialog.Portal>
          <AlertDialog.Overlay className="alert__dialog__overlay" />
          <AlertDialog.Content className="alert__dialog__content">
            <AlertDialog.Title className="alert__dialog__title">
              Deseja {isEnabled ? 'desabilitar' : 'habilitar'} esse registro?
            </AlertDialog.Title>
            <AlertDialog.Description className="alert__dialog__description">
              {isEnabled
                ? 'Você tem certeza? Esse registro não terá mais permissões nas plataformas vinculadas.'
                : 'Você tem certeza? Esse registro terá permissões nas plataformas vinculadas.'}
            </AlertDialog.Description>
            <div style={{ display: 'flex', gap: 15, justifyContent: 'flex-end', paddingTop: 15 }}>
              <AlertDialog.Cancel asChild>
                <button className="button red">Cancelar</button>
              </AlertDialog.Cancel>
              <AlertDialog.Action asChild>
                <button className="button green" onClick={() => callback(id, isEnabled ? 'disable' : 'enable')}>
                  Sim, {isEnabled ? 'desativar' : 'ativar'}
                </button>
              </AlertDialog.Action>
            </div>
          </AlertDialog.Content>
        </AlertDialog.Portal>
      </div>
    </AlertDialog.Root>
  )
}
