import { createContext, useEffect } from 'react'
import { useState } from 'react'
import { api } from '../services/api'
import { AxiosError } from 'axios'

export interface UserCompanyAdmin {
  id: number
  id_empresa: number
  id_usuario: number
  tipo: 'admin' | 'comum'
  empresas: {
    id: number
    cnpj: string
    razao_social: string
  }
}

interface AuthPayloadType {
  id: number
  token: string
  tipo: 'admin' | 'comum'
  empresas: UserCompanyAdmin[]
}

interface SignInData {
  user: string
  password: string
}

interface SignUpData {
  name: string
  email: string
  password: string
  phone: string
  city: string
  state: string
}

interface SignInReturnProps {
  status: number
  payload: AuthPayloadType | null
  error?: string
}

interface SignUpReturnProps {
  status: number
  error?: string
}

interface AuthContextType {
  isAuthenticated: boolean
  signIn: (data: SignInData) => Promise<SignInReturnProps>
  signUp: (data: SignUpData) => Promise<SignUpReturnProps>
  logout: () => Promise<void>
  selectedCompany: UserCompanyAdmin | undefined
  setSelectedCompany: React.Dispatch<
    React.SetStateAction<UserCompanyAdmin | undefined>
  >
}

export const AuthContext = createContext({} as AuthContextType)

export function AuthProvider({ children }: any) {
  const unrequiredTokenPages = [
    '/',
    '/signup',
    '/signup/confirm',
    '/account-created',
    '/email-sent',
    '/confirm-account',
    '/recover-password',
    '/invite',
    '/unverified',
  ]

  const [selectedCompany, setSelectedCompany] = useState<
    UserCompanyAdmin | undefined
  >(
    sessionStorage.getItem('@RURAUTH:selectedCompany')
      ? JSON.parse(sessionStorage.getItem('@RURAUTH:selectedCompany') || '{}')
      : undefined
  )
  const [token, setToken] = useState('')

  const isAuthenticated = !!token

  useEffect(() => {
    const tokenAlreadyExists = sessionStorage.getItem('@RURAUTH:token')

    if (tokenAlreadyExists) {
      verifyToken(tokenAlreadyExists)
    } else {
      const pathname = window.location.pathname
      if (!unrequiredTokenPages.includes(pathname)) {
        window.location.href = '/'
      }
    }
  }, [isAuthenticated])

  async function verifyToken(token: string) {
    const tokenIsValid = await api
      .get(`/usuarios/verificar-token/${token}`)
      .then((response) => {
        return response.data.valid
      })
      .catch((err) => {
        return null
      })

    if (!tokenIsValid) {
      sessionStorage.removeItem('@RURAUTH:token')
      window.location.href = '/'
    } else {
      if (window.location.pathname === '/') {
        window.location.href = '/relations/companies'
      }
    }
  }

  async function signIn({
    user,
    password,
  }: SignInData): Promise<SignInReturnProps> {
    try {
      const { data, status } = await api.post('/usuarios/dashboard/login', {
        email: user,
        senha: password,
      })

      const companiesManager = data.empresas

      if (companiesManager.length || data.tipo === 'admin') {
        if (companiesManager.length === 1) {
          sessionStorage.setItem(
            '@RURAUTH:selectedCompany',
            JSON.stringify(companiesManager[0])
          )
          setSelectedCompany(companiesManager[0])
        }

        sessionStorage.setItem('@RURAUTH:token', data.token)
        sessionStorage.setItem('@RURAUTH:email', user)
        sessionStorage.setItem('@RURAUTH:role', data.tipo)
        sessionStorage.setItem('@RURAUTH:tag', data.tag)
        sessionStorage.setItem(
          '@RURAUTH:companiesManager',
          JSON.stringify(companiesManager)
        )

        setToken(data.token)

        return { status, payload: data }
      } else {
        return {
          status: 403,
          payload: null,
          error: 'Pera aí, você não possui empresas para gerenciar.',
        }
      }
    } catch (err) {
      if (err instanceof AxiosError && err.response) {
        return {
          status: err.response.status,
          payload: null,
          error: err.response.data.error,
        }
      } else {
        return {
          status: 500,
          payload: null,
          error: 'Oops, falha na requisição.',
        }
      }
    }
  }

  async function signUp(props: SignUpData): Promise<{
    status: number
    error?: string
  }> {
    try {
      const { name, email, password, city, phone, state } = props
      const body = {
        usuario: {
          email,
          nome: name,
          senha: password,
          cidade: city,
          telefone: phone,
          uf: state,
        },
      }

      const { status } = await api.post('/usuarios', body)

      return {
        status,
      }
    } catch (err) {
      if (err instanceof AxiosError && err.response) {
        return { status: err.response.status, error: err.response.data.error }
      } else {
        return { status: 500, error: 'Oops, falha na requisição.' }
      }
    }
  }

  async function logout() {
    setToken('')
    window.sessionStorage.clear()
    window.location.href = '/'
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        signUp,
        signIn,
        logout,
        selectedCompany,
        setSelectedCompany,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
