import { useContext, useState } from 'react'
import { Flex } from '@radix-ui/themes'
import { AuthContext } from '../../contexts/AuthContext'

import * as Form from '@radix-ui/react-form'
import Loading from '../../components/loading'
import Notification from '../../components/notification'
import rurauth from '../../assets/images/rurauth.png'

import './styles.css'

type NotificationProps = {
  title: string
  description: string
}

export default function Login() {
  const { signIn } = useContext(AuthContext)

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [missPassword, setMissPassword] = useState(false)

  const [waitingRequest, setWaitingRequest] = useState(false)

  const [notificationProps, setNotificationProps] = useState<NotificationProps>(
    { title: '', description: '' }
  )
  const [notificationOpen, setNotificationOpen] = useState<boolean>(false)

  function handleSubmit(e: any) {
    e.preventDefault()
    setWaitingRequest(true)
    login()
  }

  async function login() {
    const { payload, error, status } = await signIn({
      user: email,
      password,
    }).finally(() => setWaitingRequest(false))

    setMissPassword(status === 401)

    if (status === 200) {
      window.location.href =
        payload?.tipo === 'admin' ? '/companies' : '/relations/companies'
    }

    if (!payload) {
      setNotificationProps({
        title: 'Erro!',
        description: error || 'Oops, falha na requisição.',
      })
      setNotificationOpen(true)
    }
  }

  return (
    <div className='login'>
      <div className='form__box__login'>
        <img src={rurauth} alt='ruralhub' className='logo__app' />

        <div className='form__box__login__title'>
          <h2>login</h2>

          <hr />
        </div>

        <Form.Root
          className='form__root__login'
          onSubmit={(e) => handleSubmit(e)}
        >
          <Form.Field className='form__field' name='email'>
            <div
              style={{
                display: 'flex',
                alignItems: 'baseline',
                justifyContent: 'space-between',
              }}
            >
              <Form.Label className='form__label'>email</Form.Label>
              <Form.Message className='form__message' match='valueMissing'>
                informe o seu e-mail
              </Form.Message>
              <Form.Message className='form__message' match='typeMismatch'>
                informe um e-mail válido
              </Form.Message>
            </div>
            <Form.Control asChild>
              <input
                className='input__login'
                type='email'
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Form.Control>
          </Form.Field>
          <Form.Field className='form__field' name='password'>
            <div
              style={{
                display: 'flex',
                alignItems: 'baseline',
                justifyContent: 'space-between',
              }}
            >
              <Form.Label className='form__label'>senha</Form.Label>
              <Form.Message className='form__message' match='valueMissing'>
                informe a sua senha
              </Form.Message>

              {missPassword ? (
                <Form.Message className='form__message miss__password'>
                  <a href='/recover-password'>esqueceu sua senha?</a>
                </Form.Message>
              ) : null}
            </div>
            <Form.Control asChild>
              <input
                className='input__login'
                type='password'
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </Form.Control>
          </Form.Field>
          <Flex
            justify='end'
            style={{ padding: '8px 0', columnGap: 8 }}
            className='buttons'
          >
            <button
              onClick={() => (window.location.href = '/signup')}
              type='button'
              className='button__login violet'
            >
              criar uma conta
            </button>

            <Form.Submit asChild>
              <button className='button__login green'>
                {waitingRequest ? <Loading size='small' /> : 'entrar'}
              </button>
            </Form.Submit>
          </Flex>
        </Form.Root>

        <Notification
          state={notificationOpen}
          description={notificationProps.description}
          title={notificationProps.title}
          handleState={setNotificationOpen}
        />
      </div>
    </div>
  )
}
