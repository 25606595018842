import { useState } from 'react'
import { AxiosError } from 'axios'
import { Flex } from '@radix-ui/themes'
import { api } from '../../services/api'

import * as Form from '@radix-ui/react-form'
import Loading from '../../components/loading'
import Notification from '../../components/notification'
import rurauth from '../../assets/images/rurauth.png'

import './styles.css'
import { ArrowLeftIcon } from '@radix-ui/react-icons'

type NotificationProps = {
  title: string
  description: string
}

export default function AcceptInvite() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [missPassword, setMissPassword] = useState(false)

  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const moduleId = urlParams.get('m')
  const userTag = urlParams.get('t')
  const companyId = urlParams.get('e')

  const [waitingRequest, setWaitingRequest] = useState(false)

  const [userAuthenticated, setUserAuthenticated] = useState(false)
  const [temporaryToken, setTemporaryToken] = useState('')
  const [inviteSuccessfullyAccepted, setInviteSuccessfullyAccepted] =
    useState(false)
  const [moduleDescription, setModuleDescription] = useState('')

  const [notificationProps, setNotificationProps] = useState<NotificationProps>(
    { title: '', description: '' }
  )
  const [notificationOpen, setNotificationOpen] = useState<boolean>(false)

  function handleSubmit(e: any) {
    e.preventDefault()
    setWaitingRequest(true)
    login()
  }

  async function login() {
    try {
      const { data } = await api.post('/usuarios/login', {
        email,
        senha: password,
      })

      setTemporaryToken(data.token)
      setUserAuthenticated(true)
      setWaitingRequest(false)
      acceptInvite(data.token)
    } catch (err) {
      if (err instanceof AxiosError && err.response) {
        setMissPassword(err.response.status === 401)
      }

      setNotificationProps({
        title: 'Erro!',
        description:
          err instanceof AxiosError && err.response
            ? err.response.data.error
            : 'Oops, falha na requisição.',
      })
      setNotificationOpen(true)
      setWaitingRequest(false)
    }
  }

  async function acceptInvite(token: string) {
    setWaitingRequest(true)

    try {
      const { data } = await api.post(
        '/modulos/convite/aceitar',
        {
          tag: userTag,
          id_modulo: moduleId,
          id_empresa: companyId,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )

      setModuleDescription(data.modulo)
      setWaitingRequest(false)
      setInviteSuccessfullyAccepted(true)
    } catch (err) {
      setNotificationProps({
        title: 'Erro!',
        description:
          err instanceof AxiosError && err.response
            ? err.response.data.error
            : 'Oops, falha na requisição.',
      })
      setNotificationOpen(true)
      setWaitingRequest(false)
    }
  }

  return (
    <div className='accept__invite'>
      <div className='form__box__accept__invite'>
        <img src={rurauth} alt='ruralhub' className='logo__app' />

        {waitingRequest ? (
          <Loading size='medium' />
        ) : userAuthenticated && inviteSuccessfullyAccepted ? (
          waitingRequest ? (
            <Loading size='medium' />
          ) : (
            <>
              <h1>
                convite aceito, acesso liberado na plataforma{' '}
                {moduleDescription}.
              </h1>

              <div
                className='back__button'
                onClick={() => (window.location.href = '/')}
              >
                <ArrowLeftIcon width='24' height='24' color='white' />
              </div>
            </>
          )
        ) : (
          <>
            <div className='form__root__accept__invite__title'>
              <h2>convite</h2>

              <hr />
            </div>

            <Form.Root
              className='form__root__accept__invite'
              onSubmit={(e) => handleSubmit(e)}
            >
              <Form.Field className='form__field' name='email'>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'baseline',
                    justifyContent: 'space-between',
                  }}
                >
                  <Form.Label className='form__label'>email</Form.Label>
                  <Form.Message className='form__message' match='valueMissing'>
                    informe o seu e-mail
                  </Form.Message>
                  <Form.Message className='form__message' match='typeMismatch'>
                    informe um e-mail válido
                  </Form.Message>
                </div>
                <Form.Control asChild>
                  <input
                    className='input__login'
                    type='email'
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </Form.Control>
              </Form.Field>
              <Form.Field className='form__field' name='password'>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'baseline',
                    justifyContent: 'space-between',
                  }}
                >
                  <Form.Label className='form__label'>senha</Form.Label>
                  <Form.Message className='form__message' match='valueMissing'>
                    informe a sua senha
                  </Form.Message>

                  {missPassword ? (
                    <Form.Message className='form__message miss__password'>
                      <a href='/recover-password'>esqueceu sua senha?</a>
                    </Form.Message>
                  ) : null}
                </div>
                <Form.Control asChild>
                  <input
                    className='input__login'
                    type='password'
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </Form.Control>
              </Form.Field>
              <Flex
                justify='end'
                style={{ padding: '8px 0', columnGap: 8 }}
                className='buttons'
              >
                <button
                  onClick={() => (window.location.href = '/signup')}
                  type='button'
                  className='button__login violet'
                >
                  criar uma conta
                </button>

                <Form.Submit asChild>
                  <button className='button__login green'>
                    {waitingRequest ? <Loading size='small' /> : 'entrar'}
                  </button>
                </Form.Submit>
              </Flex>
            </Form.Root>
          </>
        )}

        <Notification
          state={notificationOpen}
          description={notificationProps.description}
          title={notificationProps.title}
          handleState={setNotificationOpen}
        />
      </div>
    </div>
  )
}
